<template>
  <div class="view-outer">
    <div class="view-inner">
      <el-row :gutter="10" align="middle">
        <el-col class="left-box" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="output-box" v-html="outputSvg">
            <img src="/image/ic_qrcode.png" />
          </div>
        </el-col>
        <el-col class="right-box" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <h2 class="title">QRCode 生成器</h2>
          <el-form>
            <el-form-item label="内容">
              <el-input
                v-model="inputText"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 15 }"
                placeholder="请输入内容"
              ></el-input>
            </el-form-item>
            <el-form-item label="大小">
              <el-input v-model="inputTodo"></el-input>
            </el-form-item>
            <el-form-item label="质量">
              <el-input v-model="inputTodo"></el-input>
            </el-form-item>
            <el-form-item label="边框">
              <el-input v-model="inputTodo"></el-input>
            </el-form-item>
          </el-form>
          <el-button
            class="btn-generate"
            type="primary"
            @click="handleClickGenerate"
            >生成
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div class="debug">
      TextFormat:
      <pre>{{ outputText }}</pre>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "QRCode",

  data() {
    return {
      inputText: "xyz",
      inputTodo: "",
      outputSvg: "",
      outputText: "",
    };
  },

  methods: {
    formatQrcodeAsText(obj) {
      let modules = obj.modules;
      let size = modules.size;
      let array = modules.data;
      let str = "";
      let padding = 3;
      for (let x = 0 - padding; x < size + padding; ++x) {
        for (let y = 0 - padding; y < size + padding; ++y) {
          let index = x * size + y;
          let item = 0;
          if (0 <= x && 0 <= y && x < size && y < size) {
            item = array[index];
          }
          let ch = this.formatPixel(item);
          str += ch + ch;
        }
        str += "\n";
      }
      return str;
    },

    formatPixel(dot) {
      // ■□
      if (dot > 0.5) {
        return " ";
      } else {
        return "■";
      }
    },

    handleClickGenerate() {
      let self = this;
      let text = this.inputText;

      QRCode.toString(text, function (err, string) {
        if (err) throw err;
        console.log("qrcode.result:" + string);
        self.outputSvg = string;
      });

      let qrObject = QRCode.create(text);
      this.outputText = this.formatQrcodeAsText(qrObject);
    },
  },
};
</script>


<style scoped>
.view-outer {
  width: 95%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.view-inner {
  padding: 20px;
  margin: 20px;

  box-shadow: 0px 0px 15px #888888;
  border-radius: 5px;
}

.left-box {
  border-width: 0px;
  padding: 30px;
}

.right-box {
  border-width: 0px;
}

.output-box {
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;
}

.btn-generate {
  width: 100%;
}

.title {
  text-align: right;
}

.debug {
  text-align: left;
}
</style>